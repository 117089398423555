const url = "https://backend.elingbali.com:8082";
const kode_app = "SM";
const url_satu_peta = "https://satupeta.elingbali.com"
const kode_hrm = "SM_HRM"
const kode_sub_hrm = "SM_HRM_SUPER_ADMIN"
const allow_notif = ["SM_PUBLIC_SERVICE","SM_OPSDAL"]

const data = function (data) {

}

data.url = url;
data.kode_app = kode_app;
data.kode_hrm = kode_hrm;
data.kode_sub_hrm = kode_sub_hrm;
data.url_satu_peta = url_satu_peta;
data.allow_notif = allow_notif;

// data.verify = (data) => {
//   if (data != "" && data != "undefined" && data != undefined && data != "null" && data != null) {
//     return data;
//   }

//   return null;
// }

data.config = (method='', tujuan='', data='', id='', bearer='',second_bearer='') => {
  var mainurl = '';
  var content = '';
  if (!id) {
    mainurl = `${url}/Api/V.0.1/${tujuan}`;
  } else {
    mainurl = `${url}/Api/V.0.1/${tujuan}/${id}`;
  }

  if (data.values) {
    content = 'multipart/form-data';
  }else{
    content = 'application/json';
  }

  let rsp = {
    method: method,
    url: mainurl,
    headers: {
      token: '5b3dac76aaee24d14185cbc3d010fd20',
      bearer: bearer,
      second_bearer: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bml0X2Z1bmdzaW9uYWwiOm51bGwsImxhdCI6bnVsbCwibG5nIjpudWxsLCJwcm92X2lkIjpudWxsLCJwcm92aW5zaSI6bnVsbCwia290YV9pZCI6bnVsbCwia290YSI6bnVsbCwidmVyaWZ5IjpmYWxzZSwia29kZV9zdWJfdW5pdCI6bnVsbCwia29kZV91c2VyX21hc3RlciI6IjQyMDIwMDAxT1JHQU5JSyIsImtlc2F0dWFuIjpudWxsLCJwb2xkYV9pZCI6bnVsbCwicG9scmVzX2lkIjpudWxsLCJpZF91bmlxdWUiOiI0MjAyMDAwMSIsImtvZGVfamVuaXNfdXNlciI6Ik9SR0FOSUsiLCJrb2RlX21vZHVsIjoiIiwibW9kdWxfdXJsIjpudWxsLCJhcHBzX3VybCI6Ii9Icm0vcHJvZmlsZXVzZXIiLCJpY29uX2JvIjpudWxsLCJrb2RlX2xvZ2luIjoiYmU4Njc3MjIwNTkyN2RmMWFlYzY5M2UyODVmNWYwYWJhZjNmYmQ5YzMwYTgwMGIyNjdlZDRjZTI0YzlhYzQ2YiIsImlhdCI6MTcxMzQ5Nzk4NSwiZXhwIjozMzI0OTQ5Nzk4NX0.2Yt8by9Qzdi0_C_oKTjJB6CtTDE8Au77BoaOfH3YhAw',
      'Content-Type': content
    },
  }

  if ((method == "PUT" || method == "POST") && data.values) {
    rsp['data'] = data;
  } else {
    if (data?.length > 0) {
      data = JSON.parse(data)
      // data.ctdby = localStorage.getItem('kode_user_master');
      rsp['data'] = JSON.stringify(data);
    } else {
      // data = {
      //   kode_user_master: localStorage.getItem('kode_user_master')
      // }
      // rsp['data'] = JSON.stringify(data);
    }
  }
  return rsp;
}


data.config_auth = (method, tujuan, data, id) => {
  var mainurl = '';
  var content = '';
  if (!id) {
    mainurl = `${url}/Api/V.0.1/${tujuan}`;
  } else {
    mainurl = `${url}/Api/V.0.1/${tujuan}/${id}`;
  }
  if ((method == "PUT" || method == "POST") && (tujuan == "pangkat")) {
    content = 'multipart/form-data';
  } else {
    content = 'application/json';
  }

  let header = {
    token: '5b3dac76aaee24d14185cbc3d010fd20',
    'Content-Type': content,
  }


  let rsp = {
    method: method,
    url: mainurl,
    headers: header,
  };

  if (data) {
    rsp['data'] = data;
  }

  return rsp;
}

data.externalApi = (method='', tujuan='', data='', id='', bearer='',second_bearer='') => {
  var mainurl = '';
  var content = '';
  if (!id) {
    mainurl = `${tujuan}`;
  } else {
    mainurl = `${tujuan}/${id}`;
  }

  if (data.values) {
    content = 'multipart/form-data';
  }else{
    content = 'application/json';
  }

  let rsp = {
    method: method,
    url: mainurl,
    headers: {
      token: '5b3dac76aaee24d14185cbc3d010fd20',
      bearer: bearer,
      // second_bearer: second_bearer,
      second_bearer: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bml0X2Z1bmdzaW9uYWwiOiJBTEdPUklUTUEiLCJsYXQiOm51bGwsImxuZyI6bnVsbCwicHJvdl9pZCI6bnVsbCwicHJvdmluc2kiOm51bGwsImtvdGFfaWQiOm51bGwsImtvdGEiOm51bGwsInZlcmlmeSI6dHJ1ZSwia29kZV9zdWJfdW5pdCI6IkFMR09SSVRNQV9NQUJFUyIsImtvZGVfdXNlcl9tYXN0ZXIiOiIzMDA1T1JHQU5JSyIsImtlc2F0dWFuIjoiTUFCRVMiLCJwb2xkYV9pZCI6IiIsInBvbHJlc19pZCI6IiIsImlkX3VuaXF1ZSI6IjMwMDUiLCJrb2RlX2plbmlzX3VzZXIiOiJPUkdBTklLIiwia29kZV9tb2R1bCI6IkFMR09SSVRNQSIsIm1vZHVsX3VybCI6Imh0dHBzOi8vYWxnby1taGouZXN0cmFkYS5jby5pZDozMDAxLyIsImFwcHNfdXJsIjoiL0hybS9wcm9maWxldXNlciIsImljb25fYm8iOm51bGwsImlhdCI6MTY5NTExMTc5NywiZXhwIjoxNjk1NDcxNzk3fQ.OJhy1ASFTFnZE1kIFMqkZWqdb03O-QNQ0F6tQFfzMBY',
      'Content-Type': content
    },
  }

  if ((method == "PUT" || method == "POST") && data.values) {
    rsp['data'] = data;
  } else {
    if (data?.length > 0) {
      data = JSON.parse(data)
      // data.ctdby = localStorage.getItem('kode_user_master');
      rsp['data'] = JSON.stringify(data);
    } else {
      // data = {
      //   kode_user_master: localStorage.getItem('kode_user_master')
      // }
      // rsp['data'] = JSON.stringify(data);
    }
  }
  return rsp;
}

module.exports = data;
